import React from 'react';

const PrivacyPoilcy = () => {
    return (
        <div className="containerterm">
            <h2 className='termhead mb-4'><strong>Privacy Policy for Truck Message</strong></h2>
            <ol>
                <p>At <strong>Truck Message</strong>, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your data when you use our app and services. By using the <strong>Truck Message</strong> app, you agree to the collection and use of information in accordance with this policy.</p>





                <li><strong> Information We Collect</strong></li>

                <p>We collect the following types of information to provide and improve our services:</p>
                <ul>
                    <li><strong>a) Personal Information</strong></li>

                    <p>When you create an account or use our services, we may collect personal information such as:</p>
                    <ul>
                        <li>Name</li>
                        <li>Email address</li>
                        <li>Phone number</li>
                        <li>Payment information (for transactions)</li>
                        <li>Commercial vehicle information (such as vehicle registration number)</li>
                        <li>Adhar verification and GST certificate verification for customer (if required only for pump operators)</li>
                    </ul>



                    <li><strong>b) Usage Data</strong></li>

                    <p>We automatically collect information about how you interact with our app, including:</p>
                    <ul>
                        <li>Device information (e.g., device type, operating system)</li>
                        <li><strong>Mobile GPS Locations</strong> (for drivers only): We may collect and track the real-time GPS location of drivers using the app to provide location-based services such as load tracking, parking availability, and route optimization.</li>
                        <li>Log data (e.g., access times, app usage patterns)</li>
                        <li>IP address and device identifiers</li>
                    </ul>
                </ul>

                <li><strong> How We Use Your Information</strong></li>

                <p>We use your personal information to:</p>
                <ul>
                    <li>Provide, operate, and maintain the Truck Message app and services</li>
                    <li>Process transactions (e.g., fuel discounts, FASTag recharges)</li>
                    <li>Facilitate load bookings, driver arrangements, and truck buying/selling</li>
                    <li>Improve user experience and app functionality</li>
                    <li>Communicate with you, including sending service-related updates and promotional materials (if you opt-in)</li>
                    <li><strong>Send Updates via WhatsApp or App Notification</strong>: We will use WhatsApp or in-app notifications to send you important alerts, such as:</li>
                    <ul>
                        <li>FASTag low balance alerts</li>
                        <li>Insurance renewal reminders</li>
                        <li>Driver and fleet-related notifications</li>
                    </ul>
                    <li>Ensure security and compliance with legal requirements</li>
                    <li>Provide customer support and address inquiries or issues</li>
                </ul>

                <li><strong> Sharing Your Information</strong></li>

                <p>We may share your personal information with third parties in the following circumstances:</p>
                <ul>
                    <li><strong>Service Providers</strong>: We may share your data with trusted partners and third-party service providers who assist us in operating our app and providing services (e.g., payment processors, cloud storage providers).</li>
                    <li><strong>Legal Compliance</strong>: We may disclose your information to comply with legal obligations, regulations, or respond to lawful requests from government authorities.</li>
                    <li><strong>Business Transfers</strong>: In the event of a merger, acquisition, or sale of assets, your personal information may be transferred as part of that transaction.</li>
                    <li><strong>Third-Party Use: </strong>To personalize your experience on the app. To perform behavioral analytics for better service delivery. To share information with vendors, consultants, marketing partners, research firms, payment processors, and other service providers supporting our services. <br /> To support academic research through academic partners.</li>
                </ul>

                <li><strong> Data Security</strong></li>

                <p>We take reasonable precautions to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your data, we cannot guarantee absolute security.</p>

                <li><strong> Your Rights and Choices</strong></li>

                <p>Depending on your location, you may have certain rights regarding your personal data, including:</p>
                <ul>
                    <li><strong>Access and Correction</strong>: You can access and update your personal information directly through the app.</li>
                    <li><strong>Data Deletion</strong>: You can request the deletion of your personal data, subject to legal and contractual obligations.</li>
                    <li><strong>Opt-out of Marketing</strong>: You can choose to opt-out of receiving marketing communications by following the unsubscribe instructions in emails or by contacting us directly.</li>
                    <li><strong>Data Portability</strong>: You may request a copy of your data in a machine-readable format.</li>
                </ul>

                <li><strong> Retention of Data</strong></li>

                <p>We will retain your personal information for as long as necessary to provide services, comply with legal obligations, resolve disputes, and enforce our agreements. Once your information is no longer needed, we will securely delete or anonymize it.</p>

                <li><strong> Third-Party Links</strong></li>

                <p>The Truck Message app may contain links to third-party websites or services. We are not responsible for the privacy practices or the content of such third parties. We encourage you to review the privacy policies of any external sites you visit.</p>

                <li><strong> Children's Privacy</strong></li>

                <p>Our services are not directed to children under the age of 13, and we do not knowingly collect personal data from children. If we become aware that we have inadvertently collected data from a child under the age of 13, we will take steps to delete such information immediately.</p>

                <li><strong> Changes to This Privacy Policy</strong></li>

                <p>We may update our Privacy Policy from time to time. Any changes will be reflected on this page, and the "Effective Date" at the top of this page will be updated. We encourage you to review this Privacy Policy periodically for any changes.</p>

                <li><strong> Driver Partner Data </strong></li>

                <p>If you are a Driver Partner, certain details like your name, phone number, and profile picture (if applicable) may be shared with customers to provide services.</p>

                <li><strong> Legal and Compliance</strong></li>

                <p>To comply with applicable laws, government orders, or legal processes.<br /> To prevent fraud, identity theft, or abuse of services.<br /> To detect and act against illegal activities.</p>

                <li><strong> Contact Us</strong></li>

                <p>If you have any questions or concerns about this Privacy Policy or how we handle your personal information, please contact us at:</p>
                <p><strong>Truck Message</strong><br /> [Insert Address]<br /> Email: [Insert Contact Email]<br /> Phone: [Insert Phone Number]</p>
            </ol>
        </div>
    );
};

export default PrivacyPoilcy;
