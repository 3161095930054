import React from 'react';

const TermsAndConditions = () => {
    return (
        <div className="containerterm">
            <h2 className='termhead mb-4'><strong>Truck Message Terms and Conditions</strong></h2>


            <p>Welcome to Truck Message! By using our services, you are agreeing to the following Terms and Conditions. Please read them carefully.</p>
            <ol>
                <li><strong> Introduction</strong></li>
                <p>These Terms and Conditions ("Terms") govern your use of the Truck Message app ("App"), including the features and services we offer, such as load and truck booking, driver arrangements, truck buying and selling, truck parking, discounted fuel, load tracking, FASTag recharge, and more (collectively referred to as "Services"). By accessing or using the App, you agree to comply with these Terms.<br /> If you do not agree with these Terms, please refrain from using the App or Services.</p>
                <p> </p>
                <p>The terms and conditions listed below, which regulate this agreement between you and us about the services rendered through the Truck Message App, are defined as follows:</p>
                <p><strong>[Insert Company Name],</strong> its staff, agents, and approved service providers who provide or enable the services made accessible through the Truck Message App are referred to as "We," "Us," "Our," "Company," or "Truck Message."</p>
                <p>Any individual, company, or other organization accessing or using the Truck Message App to transmit, receive, or manage truck- related communications, as well as any other party having a legal interest in these communications.</p>
                <p>"Services" refers to the features and capabilities that the Truck Message App provides, such as but not restricted to:</p>
                <p>Sending and receiving communications between users about trucks.<br /> Handling alerts about truck services or activities.<br /> Supplying truck communications records and updates.</p>
                <p>The Truck Message App occasionally introduces additional related features.</p>
                <p>Any text, alert, notification, or communication made or received using the Truck Message App is referred to as a "message."</p>
                <p>Any message or communication that contravenes applicable laws, contains objectionable or dangerous content, or violates the terms specified in this agreement is referred to as "Prohibited Content."</p>
                <p>&ldquo;Delivery&rdquo; refers to the successful transmission of a message to the intended recipient via the Truck Message App.</p>
                <p>&ldquo;Applicable Law&rdquo; means all relevant laws, statutes, regulations, guidelines, and rules enforced by government or legal authorities in the jurisdiction where the Truck Message App operates.</p>

                <li><strong> Services Provided</strong></li>

                <p>Truck Message offers a variety of services, including but not limited to:</p>
                <ul>
                    <li>Load &amp; Truck Booking</li>
                    <li>Driver Arrangements</li>
                    <li>Truck Buying &amp; Selling</li>
                    <li>Truck Parking</li>
                    <li>Discounted Petrol Pump Locator</li>
                    <li>Load Tracking</li>
                    <li>Toll Gate &amp; Mileage Calculators</li>
                    <li>FASTag Recharge</li>
                    <li>Commercial Insurance Renewal</li>
                    <li>E-Commerce Functionality (for the sale and purchase of trucking-related products, accessories, and services)</li>
                    <li><strong>Blog Posts Regarding Transporters' News</strong>: Truck Message may provide blog posts and articles relevant to the transport industry, keeping you updated on the latest trends, regulatory changes, and news affecting transporters, truckers, and logistics companies.</li>
                </ul>
                <p>These services are designed to streamline your trucking operations and provide tools for more efficient logistics management. We may update, modify, or discontinue certain features or services at our discretion, including the introduction of e-commerce functionality within the app.</p>

                <li><strong> Eligibility/</strong> <strong>Acceptance of Terms</strong></li>

                <p><strong> </strong></p>
                <p>You must be at least 18 years of age to use this app. By using our services, you represent and warrant that you are at least 18 years old and have the legal capacity to enter into these Terms.</p>

                <li><strong> User Account</strong></li>

                <p>To access and use certain features of the App, you may need to create a user account. You agree to provide accurate, current, and complete information during the registration process and to update such information as necessary to maintain its accuracy. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>

                <li><strong> Usage Restrictions</strong></li>

                <p>You agree to use the App only for lawful purposes and in compliance with applicable laws and regulations. Prohibited uses include, but are not limited to:</p>
                <p>You agree not to:</p>
                <ul>
                    <li>Sending spam or unauthorized advertisements.</li>
                    <li>Harassing or abusing other users.</li>
                    <li>Disrupting the functionality of the App.</li>
                    <li>Use the App or Services for any unlawful purpose or in a way that violates these Terms</li>
                    <li>Attempt to access, interfere with, or disrupt the functionality of the App or its servers.</li>
                    <li>Use the App to post or transmit any harmful, offensive, or defamatory content.</li>
                    <li>Use the App to engage in fraud, scams, or any illegal activities.</li>
                </ul>

                <li><strong> Payment &amp; Fees</strong></li>

                <p>Some features of the Truck Message app may require payment, such as insurance renewals and FASTag recharges. Payments for these services will be processed through our third-party payment processor. You agree to comply with the terms and conditions of the respective payment provider.<br /> Truck Message reserves the right to change, modify, or discontinue its pricing structure at any time.</p>

                <li><strong> Subscription Service (Future Feature)</strong></li>

                <p>In the future, Truck Message may offer a subscription-based model for accessing premium features or services. If and when this happens, the details, pricing, and terms of the subscription will be provided at the time of availability. By subscribing to any subscription plan, you agree to the following terms:</p>
                <ul>
                    <li><strong>Billing</strong>: Subscription fees will be billed on a recurring basis (e.g., monthly, annually) as specified at the time of purchase.</li>
                    <li><strong>Cancellation</strong>: You may cancel your subscription at any time through your account settings or by contacting customer support. Cancellation will take effect at the end of the current billing cycle.</li>
                    <li><strong>Refunds</strong>: Unless otherwise stated, subscription fees are non-refundable.</li>
                </ul>
                <p>Truck Message reserves the right to change, suspend, or discontinue its subscription offerings at any time.</p>

                <li><strong> Load and Truck Booking</strong></li>

                <p>Truck Message provides a platform for users to book loads and trucks. We act as an intermediary and do not guarantee the availability or suitability of trucks or loads listed on the platform. Users must exercise due diligence when booking trucks or loads and ensure all parties involved agree to the terms before proceeding.</p>

                <li><strong> Driver Arrangements</strong></li>

                <p>Truck Message facilitates connections between users and qualified drivers but is not responsible for the actions or performance of drivers. You are responsible for vetting drivers and ensuring they meet your requirements.</p>

                <li><strong> Commercial Insurance Renewal</strong></li>

                <p>Truck Message allows users to manage their truck&rsquo;s insurance renewal through the app. It is your responsibility to ensure that your insurance coverage is adequate for your needs. We do not assume liability for any gaps in your insurance coverage.</p>

                <li><strong> Reward Points for Insurance Renewal, FASTag Recharge &amp; E-Commerce Purchases</strong></li>

                <p>Truck Message offers reward points for customers who complete certain transactions, such as insurance renewals, FASTag recharges, and e-commerce purchases (if applicable). These points may be redeemed for discounts on future services or other rewards.<br /> Truck Message reserves the right to modify, suspend, or discontinue the reward points program at any time. Points are non-transferable and have no cash value.</p>

                <li><strong> E-Commerce Functionality</strong></li>

                <p>Truck Message may, in the future, offer e-commerce services that allow you to purchase products, accessories, or services related to trucking and logistics (e.g., truck parts, maintenance services, equipment). These services will be governed by additional terms and conditions specific to e-commerce transactions.<br /> Truck Message is not responsible for transactions between users and third-party sellers or service providers within the e-commerce section of the App.</p>

                <li><strong> Referral Program and Rewards for Referring New Users</strong></li>

                <p>Truck Message may offer a <strong>Referral Program</strong> as part of its promotion, allowing <strong>existing users</strong> to earn rewards for referring new users to the App.<br /> Under this program, an existing user ("Referrer") may receive a reward, such as bonus points or discounts, for each successful referral of a new user ("Referred User") who signs up and uses the App. Specific details, including the reward value and eligibility criteria, will be provided in the referral program terms available on the App or website.</p>
                <ul>
                    <li><strong>Eligibility</strong>: The Referrer must have an active account and meet any conditions specified by Truck Message for the referral reward program to apply.</li>
                    <li><strong>Referral Reward</strong>: The reward for the Referrer will be provided once the Referred User completes the required actions (e.g., making a first transaction, signing up, etc.).</li>
                    <li><strong>Program Modifications</strong>: Truck Message reserves the right to modify or discontinue the referral program at any time without prior notice. Rewards may not be transferable and have no cash value.</li>
                </ul>

                <li><strong> User Content</strong></li>

                <p>By using the App, you may submit content, including but not limited to reviews, ratings, and messages. You retain ownership of any content you submit, but by submitting content, you grant Truck Message a worldwide, royalty-free, and irrevocable license to use, modify, and display such content in connection with the App and its services.</p>
                <p> </p>

                <li><strong> Privacy and Data Protection</strong></li>

                <p>Your privacy is important to us. Please review our [Privacy Policy] to understand how we collect, use, and protect your personal information.</p>

                <li><strong> Termination</strong></li>

                <p>We may suspend or terminate your access to the App and Services at our discretion, without notice, for any violation of these Terms. Upon termination, you must immediately cease using the App.</p>

                <li><strong> Disclaimer of Warranties</strong></li>

                <p>Truck Message provides the App and Services "as is" and without any warranties or representations, express or implied, regarding the accuracy, completeness, or reliability of the services. We do not guarantee that the App will be uninterrupted, error-free, or free from viruses.</p>

                <li><strong> Limitation of Liability</strong></li>

                <p>To the fullest extent permitted by law, Truck Message is not liable for any direct, indirect, incidental, special, or consequential damages arising from your use or inability to use the App, including but not limited to loss of data, business interruptions, or financial loss.</p>

                <li><strong> Indemnification</strong></li>

                <p>You agree to indemnify and hold harmless Truck Message, its affiliates, employees, agents, and third-party partners from any claims, losses, liabilities, damages, and expenses arising from your use of the App or violation of these Terms.</p>

                <li><strong> Governing Law</strong></li>

                <p>These Terms are governed by and construed in accordance with the laws of [Insert Country/State]. Any disputes arising out of or relating to these Terms will be resolved through binding arbitration in [Insert City or State], unless otherwise required by law.</p>

                <li><strong> Changes to the Terms</strong></li>
                <p>Truck Message reserves the right to update or modify these Terms at any time. Any changes will be effective immediately upon posting on the App or our website. It is your responsibility to review these Terms periodically for updates. Continued use of the App constitutes your acceptance of any changes.</p>

                <li>[Truck Message App] retains sole ownership or lawful licensing rights over all content and materials on its platform, including but not limited to design, layout, text, images, graphics, sound, and video. This content is protected by global copyright, trademark, and other intellectual property laws and embodies proprietary trade secrets. All ownership and intellectual property rights remain with [Truck Message App], its affiliates, or its licensors, as applicable.</li>


                <p>All rights not explicitly granted under this agreement or by [Truck Message App] are reserved. The information provided on the platform is intended solely for general, personal use, and users assume full responsibility for their use of the content. [Truck Message App] does not guarantee the accuracy, reliability, or completeness of any information, advertisements, or materials accessed through its platform. All content is provided &ldquo;AS IS,&rdquo; without warranties of any kind, whether express or implied, including but not limited to performance, merchantability, or fitness for a particular purpose. Users are encouraged to exercise independent judgment and investigation.</p>
                <p>[Truck Message App] disclaims all liability for errors, omissions, or outcomes resulting from the use of its content. Under no circumstances will [Truck Message App] be liable for any direct, indirect, incidental, punitive, or consequential damages arising from its services. Reliance on platform content is solely at the user&rsquo;s risk.</p>
                <p>[Truck Message App] reserves the right to improve or correct errors in its services or content at its sole discretion and without prior notice.</p>
                <p><strong>Trademarks</strong></p>
                <p>All logos, icons, and related marks on the platform are registered trademarks, trademarks, or service marks of [Truck Message App], protected under applicable laws. Unauthorized use, modification, or copying of these marks is strictly prohibited.</p>

                <p><strong>Copyright</strong></p>
                <p>All platform content is copyrighted by [Truck Message App], except for third-party content and links to external websites.</p>
                <p>Systematic retrieval of [Truck Message App] content, whether through automated tools or manual processes, to create a collection, compilation, database, or directory without written permission is strictly prohibited.</p>
                <p>Unauthorized use of the content for purposes not explicitly permitted by this agreement may result in legal action. As a condition of accessing and using [Truck Message App] services, users agree not to infringe on its intellectual property rights or those of third parties.</p>
                <p>[Truck Message App] reserves the right to terminate user accounts in cases of intellectual property infringement or conduct deemed harmful to the platform, its affiliates, or other users. Users are liable to indemnify [Truck Message App] for any losses or expenses resulting from such infringements, without prejudicing the platform&rsquo;s right to pursue legal action.</p>

                <li><strong>LINKS TO THIRD-PARTY SITES</strong></li>

                <p>The [Truck App] may provide links to third-party websites for the convenience of its users. However, [Truck App] does not have any control over the content, resources, or services provided by these third-party sites.</p>
                <p>[Truck App] may offer access to third-party content, products, or services through hyperlinks, which can appear as text links, banners, or other formats. Users are advised to carefully review the terms and conditions and privacy policies of these third-party websites before using them to understand their rules and practices.</p>
                <p>[Truck App] does not monitor or endorse third-party websites and is not responsible or liable for their content, products, or services. Users acknowledge that their interactions with third-party sites are at their own discretion and risk.</p>
                <p>Users are encouraged to review [Truck App]&rsquo;s Privacy Policy and adhere to its terms when interacting with [Truck App], especially regarding the handling and sharing of personal information and data.</p>


                <li><strong> Contact Information</strong></li>

                <p>If you have any questions or concerns about these Terms, please contact us at:<br /> Truck Message<br /> [Insert Company Address]<br /> [Insert Email Address]<br /> [Insert Phone Number]</p>
            </ol>

        </div>
    );
};

export default TermsAndConditions;
