import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Banner = () => {
  const publicUrl = `${process.env.PUBLIC_URL}/`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section
      className="ltn__slider-area ltn__slider-3 section-bg-1 go-top"
      aria-label="Banner section for truck solutions"
    >
      <div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1">
        <article className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3">
          <div className="ltn__slide-item-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 align-self-end">
                  <div className="slide-item-info w-100 h-100">
                    <div className="slide-item-info-inner ltn__slide-animation">
                      <h6 className="slide-sub-title white-color--- animated">
                        <span>
                          <i className="fa-solid fa-truck"></i>
                        </span>{' '}
                        All in One Truck Solution
                      </h6>
                      <h1 className="slide-title animated">
                        Optimize Your Trucking Operations with <br /> Our
                        All-in-One Solutions
                      </h1>
                      <div className="slide-brief animated">
                        <p className="para">
                          "Experience seamless logistics management and maximize efficiency with our comprehensive suite of trucking solutions."
                        </p>
                      </div>
                      <div className="btn-wrapper animated">
                        <Link
                          to="/contact"
                          className="btn btn-danger ps-4 pt-2 pb-2 pe-4 go-top"
                          aria-label="Make an Enquiry"
                        >
                          Make An Enquiry
                        </Link>
                      </div>
                    </div>
                  </div>
                  <figure className="slide-item-img">
                    <img
                      src={`${publicUrl}assets/img/slider/21.jpg`}
                      alt="Truck logistics solutions"
                      loading="lazy"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </section>
  );
};

export default Banner;
